import React, { useEffect, useState } from "react";
import { useAppContext } from "../../store/AppContext";
import { Event, PATHS } from "../../firebase/types";
import ListElementEvents from "./ListElementEvents";
import { useNavigate } from "react-router-dom";

function Events() {
  const { currentUser, isMobile, events, get_events } = useAppContext();
  const [reqComplete, setReqComplete] = useState(false);

  useEffect(() => {
    if (!currentUser) return;
    (async () => {
      await get_events();
      setReqComplete(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!currentUser && !isMobile) return <IntroMessage />;
  if (!reqComplete) return null;
  if (!events || !events.length) {
    return <p>You have no events that you need to attend</p>;
  }

  return (
    <>
      {events && (
        <ul>
          {events
            .filter((ev) => ev.timestamp > Date.now())
            .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
            .map((event: Event, idx: number) => (
              <ListElementEvents key={idx} event={event} />
            ))}
        </ul>
      )}
    </>
  );
}

export default Events;

function IntroMessage() {
  const navigate = useNavigate();

  return (
    <>
      <br />
      <br />
      <p className="Title_Bigger TextAlignCenter">Simplify your work</p>
      <br />

      <p>1. Update your unique Handle</p>
      <p className="TextSecondary">Used to show people your events</p>
      <br />

      <p>2. Create a training plan</p>
      <p className="TextSecondary">Use plans to create Workouts for Events</p>
      <br />

      <p>3. Create an Event Type</p>
      <p className="TextSecondary">
        Events are automatically created when clients join Event Types
      </p>
      <br />

      <p className="Button" onClick={() => navigate(`/${PATHS.USER}`)}>
        See User details
      </p>
      <p className="TextSecondary TextAlignCenter">
        You will be redirected for Authentication
      </p>
    </>
  );
}
